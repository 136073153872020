@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

html {
  --color-bg-primary: #f2f2f4;
  --color-bg-secondary: #ffffff;
  --color-bg-thirdry: #f2f2f4;
  --color-bg-fourth: #61abb9;
  --color-bg-overlay: #e3e4e8;
  --color-bg-table-row: #f9fbfd;
  --color-bg-success: #24a148;
  --color-bg-danger: #fa4d56;
  --color-text-primary: #070707;
  --color-text-secondary: #7e7e7e;
  --color-text-thirdry: #1fa2f9;
  --color-text-accent: #33385f;
  --color-fill-secondary: #7e7e7e;
  --gradient-bg-primary: linear-gradient(to top bottom, #b0d2eb, white);
  --gradient-button-primary: linear-gradient(to left, #26c8bf, #1fa2f9);
  height: 100%;
}
